import React from 'react';

import Modal from 'components/Modal';
import ResultDisplay from 'components/ResultDisplay';
// import { useSocialSharing } from 'components/Share';

export default function ResultModal({ result, ...props }) {
  // const { openShareMenu } = useSocialSharing();

  return (
    <Modal closeButton={true} closeOnOverlayClick={true} {...props}>
      <ResultDisplay result={result} />

      {/* <p onClick={openShareMenu}>Share</p> */}
    </Modal>
  );
}
