import React from 'react';
import { useLocation } from '@reach/router';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import {
  getExt,
  getFormats,
  isDownloadable,
  sortFormatsByType,
  sortByDownloadable,
} from 'lib/utils';
import { Tabs, TabPanel } from 'components/Tabs';
import DownloadPanel from 'components/DownloadPanel';
import ConvertPanel from 'components/ConvertPanel';
import MergePanel from 'components/MergePanel';
import SubtitlesPanel from 'components/SubtitlesPanel';

export default function VideoDownload({ info, ...props }) {
  const { t } = useTranslation();

  const location = useLocation();
  const isConvertPage = location?.pathname.endsWith('/converter');
  const activeTabIndex = isConvertPage ? 1 : 0;

  const originalFormats = getFormats(info);
  const hasFormats = originalFormats.length > 0;

  // Reverse the formats to get the best format first
  // and add an "isDownloadable" property and "ext" property if missing
  const formats = React.useMemo(() => {
    return originalFormats
      .map((format) => ({
        ...format,
        isDownloadable: isDownloadable(format),
        // Some format objects don't have the "ext" property
        ext: format.ext ?? getExt(format.url, t('common:unknown')),
      }))
      .reverse();
  }, [t, originalFormats]);

  // Formats sorted by type (video, audio, videoOnly)
  const sortedFormats = React.useMemo(() => {
    return sortByDownloadable(sortFormatsByType(formats));
  }, [formats]);

  const hasVideoOnlyFormats = (sortedFormats?.videoOnly.length ?? 0) > 0;
  const hasAudioOnlyFormats = (sortedFormats?.audio.length ?? 0) > 0;
  const canMerge = hasVideoOnlyFormats && hasAudioOnlyFormats;

  const hasSubtitles = Object.keys(info?.subtitles ?? {}).length > 0;
  // Video has automatic captions (YouTube only)
  const hasCaptions = Object.keys(info?.automatic_captions ?? {}).length > 0;

  const tabs = [t('common:download'), t('common:convert')];

  if (canMerge) {
    tabs.push(t('common:merge'));
  }

  if (hasSubtitles) {
    tabs.push(t('common:subtitles'));
  }

  if (hasCaptions) {
    tabs.push(t('common:captions'));
  }

  return (
    <div {...props}>
      {hasFormats ? (
        <Tabs className="mb-4" tabs={tabs} activeTabIndex={activeTabIndex}>
          <TabPanel>
            <DownloadPanel
              info={info}
              formats={formats}
              sortedFormats={sortedFormats}
            />
          </TabPanel>

          <TabPanel>
            <ConvertPanel info={info} cleanup={!isConvertPage} />
          </TabPanel>

          {canMerge && (
            <TabPanel>
              <MergePanel
                info={info}
                videoFormats={sortedFormats.videoOnly}
                audioFormats={sortedFormats.audio}
              />
            </TabPanel>
          )}

          {hasSubtitles && (
            <TabPanel>
              <SubtitlesPanel
                subtitles={info.subtitles}
                buttonText={t('common:dl_subtitles')}
              />
            </TabPanel>
          )}

          {hasCaptions && (
            <TabPanel>
              <SubtitlesPanel
                subtitles={info.automatic_captions}
                buttonText={t('common:dl_captions')}
              />
            </TabPanel>
          )}
        </Tabs>
      ) : (
        <p>{t('common:errors.no_formats')}</p>
      )}
    </div>
  );
}
