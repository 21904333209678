import React from 'react';

import { getTimeObject } from 'lib/utils';

function pad(n) {
  return `${n < 10 ? '0' : ''}${n}`;
}

function reducer(state, update) {
  return { ...state, ...update };
}

export default function TimePicker({
  className,
  value = 0,
  duration = 0,
  onChange,
}) {
  const totalDuration = getTimeObject(duration);

  const [state, setState] = React.useReducer(reducer, undefined, () => {
    // Set the initial duration
    if (value !== 0) {
      // Do not allow the initial value to exceed the total duration
      value = value > duration ? duration : value;

      return getTimeObject(value);
    }

    return { hours: 0, minutes: 0, seconds: 0 };
  });

  const display = {
    hours: totalDuration.hours,
    // Show the maximum number of minutes we can select, otherwise show 59 minutes
    minutes: state.hours === totalDuration.hours ? totalDuration.minutes : 59,
    // Show the maximum number of seconds we can select, otherwise show 59 seconds
    seconds:
      state.hours === totalDuration.hours &&
      state.minutes === totalDuration.minutes
        ? totalDuration.seconds
        : 59,
  };

  React.useEffect(() => {
    const { hours, minutes, seconds } = state;

    const changeMinutes = minutes > display.minutes;
    const changeSeconds = seconds > display.seconds;

    if (changeMinutes) {
      setState({ minutes: display.minutes });
    }

    if (changeSeconds) {
      setState({ seconds: display.seconds });
    }

    if (!changeMinutes && !changeSeconds) {
      const total = seconds + minutes * 60 + hours * 60 * 60;
      onChange?.(total > duration ? duration : total);
    }
  }, [state, display.minutes, display.seconds, duration, onChange]);

  return (
    <div className={`inline-flex border rounded-md p-2 shadow-sm ${className}`}>
      {display.hours > 0 && (
        <>
          <select
            placeholder="00"
            className="px-1 py-0 appearance-none bg-transparent bg-none border-none focus:ring-0"
            value={state.hours}
            onChange={(e) => setState({ hours: Number(e.target.value) })}
          >
            {[...new Array(display.hours + 1)].map((_, i) => (
              <option key={i} value={i}>
                {pad(i)}
              </option>
            ))}
          </select>
          <span className="px-2">:</span>
        </>
      )}

      <select
        placeholder="00"
        className="px-1 py-0 appearance-none bg-transparent bg-none border-none focus:ring-0"
        value={state.minutes}
        onChange={(e) => setState({ minutes: Number(e.target.value) })}
      >
        {[...new Array(display.minutes + 1)].map((_, i) => (
          <option key={i} value={i}>
            {pad(i)}
          </option>
        ))}
      </select>

      <span className="px-2">:</span>

      <select
        placeholder="00"
        className="px-1 py-0 appearance-none bg-transparent bg-none border-none focus:ring-0"
        value={state.seconds}
        onChange={(e) => setState({ seconds: Number(e.target.value) })}
      >
        {[...new Array(display.seconds + 1)].map((_, i) => (
          <option key={i} value={i}>
            {pad(i)}
          </option>
        ))}
      </select>
    </div>
  );
}
