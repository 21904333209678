import React from 'react';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

import FormatSelect from 'components/FormatSelect';
import Button from 'components/Button';
import DurationRangePicker from 'components/DurationRangePicker';
import ServerDownloadButton from 'components/ServerDownloadButton';
import useCutFileState from 'hooks/useCutFileState';
import { trackPromotion } from 'lib/analytics';
import { getDlLink, compressLink } from 'lib/videoproc';

export default function DownloadPanel({ info, formats, sortedFormats }) {
  const { t, i18n } = useTranslation();

  const [formatID, setFormatID] = React.useState(
    sortedFormats.video[0]?.format_id ??
      sortedFormats.audio[0]?.format_id ??
      sortedFormats.videoOnly[0]?.format_id
  );

  const selectedFormat = formats.find(
    ({ format_id }) => format_id === formatID
  );

  const { cutFile, setCutFile, cutFrom, setCutFrom, cutTo, setCutTo } =
    useCutFileState({ info });

  return (
    <div>
      <FormatSelect
        label={t('common:labels.select_format')}
        id="formats"
        formats={sortedFormats}
        value={formatID}
        onChange={setFormatID}
      />

      <DurationRangePicker
        className="mt-4"
        label={t('common:labels.cut_file')}
        enabled={cutFile}
        onChange={setCutFile}
        duration={info.duration ?? 86400}
        start={cutFrom}
        stop={cutTo}
        onStartChange={setCutFrom}
        onStopChange={setCutTo}
      />

      {selectedFormat.isDownloadable && !cutFile ? (
        <>
          <Button
            as="a"
            href={selectedFormat.url}
            color="secondary"
            target="_blank"
            rel="noopener noreferrer"
            referrerPolicy="no-referrer"
            className="w-full mt-4"
          >
            {t('common:download_format', {
              format: selectedFormat.ext.toUpperCase(),
            })}
          </Button>

          <p className="text-sm mt-1 text-gray-700">
            <Trans
              i18nKey="common:right_click_save"
              components={[<span className="text-blue-500" />]}
            />
          </p>
        </>
      ) : (
        <ServerDownloadButton
          // Force re-render on format change
          key={formatID}
          text={cutFile ? t('common:start_dl_cut') : t('common:start_dl')}
          info={info}
          format={formatID}
          cutFrom={cutFile ? cutFrom : null}
          cutTo={cutFile ? cutTo : null}
          initialStatus={t('common:click_to_start_dl')}
          className="w-full mt-4 cursor-pointer"
        />
      )}

      <Button
        as="a"
        color="success"
        href={getDlLink(i18n.language)}
        target="_blank"
        rel="nofollow noreferrer noopener"
        className="relative w-full mt-4 font-bold"
        onClick={() => trackPromotion('btn-4k')}
      >
        {t('videoproc:download_4k')}
        <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
        </span>
      </Button>

      <Button
        as="a"
        color="success"
        href={compressLink}
        target="_blank"
        rel="nofollow noreferrer noopener"
        className="w-full mt-4 font-bold"
        onClick={() => trackPromotion('btn-comp1')}
      >
        {t('videoproc:edit')}
      </Button>
    </div>
  );
}
