import React from 'react';
import { useQueryParams, NumberParam } from 'use-query-params';

export default function useCutFileState({ info }) {
  const [query, setQuery] = useQueryParams({
    from: NumberParam,
    to: NumberParam,
  });

  const { from: cutFrom, to: cutTo } = query;

  const setCutFrom = React.useCallback((from) => setQuery({ from }), [
    setQuery,
  ]);

  const setCutTo = React.useCallback((to) => setQuery({ to }), [setQuery]);

  const [cutFile, setCutFile] = React.useState(() => !!(cutFrom || cutTo));

  // When disabled remove the query params
  React.useEffect(() => {
    if (!cutFile) {
      setQuery({ from: undefined, to: undefined });
    }
  }, [cutFile, setQuery]);

  return {
    cutFile,
    setCutFile,
    cutFrom: cutFrom ?? 0,
    setCutFrom,
    cutTo: cutTo ?? info.duration ?? 86400,
    setCutTo,
  };
}
