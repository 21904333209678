import React from 'react';
import { Transition } from '@headlessui/react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import TimePicker from 'components/TimePicker';
import AlertError from 'components/AlertError';

export default function RangePicker({
  show = true,
  start,
  stop,
  duration,
  onStartChange,
  onStopChange,
  className,
}) {
  const { t } = useTranslation();

  const error =
    start === stop
      ? t('common:errors.start_eq_end_time')
      : start > stop
      ? t('common:errors.start_gt_end_time')
      : null;

  return (
    <Transition
      show={show}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className={`transition-opacity ${className}`}
    >
      <div className="flex justify-between">
        <div>
          <p className="block text-sm font-medium text-gray-700">
            {t('common:labels.from')}
          </p>
          <TimePicker
            duration={duration}
            value={start}
            onChange={onStartChange}
            className="mt-1"
          />
        </div>

        <div>
          <p className="block text-sm font-medium text-gray-700">
            {t('common:labels.to')}
          </p>
          <TimePicker
            duration={duration}
            value={stop}
            onChange={onStopChange}
            className="mt-1"
          />
        </div>
      </div>

      {error && (
        <AlertError className="mt-2">
          <p className="text-sm font-medium">{error}</p>
        </AlertError>
      )}
    </Transition>
  );
}
