import { encodeString, decodeString } from 'use-query-params';

// A string query param config with a default value of an empty string instead of undefined
// See: https://github.com/pbeshai/use-query-params/issues/147
export const DefaultStringParam = {
  encode: (str) => {
    // By replacing these values with undefined, they are removed from the URL
    if (str === '' || str == null) return undefined;

    return encodeString(str);
  },

  decode: (urlStr) => {
    const decoded = decodeString(urlStr);

    // Coerce nully values to empty string for usage in app
    if (decoded == null) return '';

    return decoded;
  },
};
