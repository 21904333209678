import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import {
  videoFormats,
  audioFormats,
  audioCBR,
  audioVBR,
  getFormatLabel,
  getQualityLabel,
  formatBitrate,
} from 'lib/convert-utils';

import Select from 'components/Select';
// import Button from 'components/Button';
import DurationRangePicker from 'components/DurationRangePicker';
import ServerDownloadButton from 'components/ServerDownloadButton';
import useCutFileState from 'hooks/useCutFileState';
import useConvertState from 'hooks/useConvertState';
// import { trackPromotion } from 'lib/analytics';
// import { getStartedLink2, compressLink } from 'lib/videoproc';

export default function ConvertPanel({ info, cleanup = true }) {
  const { t } = useTranslation();

  const {
    format,
    setFormat,
    quality,
    setQuality,
    qualitySupported,
    VBRSupported,
  } = useConvertState({ cleanup });

  const { cutFile, setCutFile, cutFrom, setCutFrom, cutTo, setCutTo } =
    useCutFileState({ info });

  const convertKey = `${format}${qualitySupported ? '-' + quality : ''}${
    cutFile ? '-' + cutFrom + '-' + cutTo : ''
  }`;

  return (
    <div>
      <Select
        id="convert-format"
        label={t('common:labels.select_format')}
        value={format}
        onChange={(e) => setFormat(e.target.value)}
      >
        <optgroup label={t('common:labels.audio_formats')}>
          {audioFormats.map((format) => (
            <option key={format} value={format}>
              {getFormatLabel(format)}
            </option>
          ))}
        </optgroup>

        <optgroup label={t('common:labels.video_formats')}>
          {videoFormats.map((format) => (
            <option key={format} value={format}>
              {format.toUpperCase()}
            </option>
          ))}
        </optgroup>
      </Select>

      {qualitySupported && (
        <Select
          id="convert-quality"
          label={t('common:labels.select_quality')}
          value={quality}
          onChange={(e) => setQuality(e.target.value)}
          className="mt-4"
        >
          <optgroup label={t('common:labels.cbr')}>
            {audioCBR.map((quality) => {
              const label = getQualityLabel(quality);

              return (
                <option key={quality} value={quality}>
                  {formatBitrate(quality)}
                  {label ? ` (${label})` : ''}
                </option>
              );
            })}
          </optgroup>

          <optgroup label={t('common:labels.vbr')} disabled={!VBRSupported}>
            {audioVBR.map((quality) => {
              const label = getQualityLabel(quality);

              return (
                <option key={quality} value={quality}>
                  {quality}
                  {label ? ` (${label})` : ''}
                </option>
              );
            })}
          </optgroup>
        </Select>
      )}

      <DurationRangePicker
        className="mt-4"
        label={t('common:labels.cut_file')}
        enabled={cutFile}
        onChange={setCutFile}
        duration={info.duration ?? 86400}
        start={cutFrom}
        stop={cutTo}
        onStartChange={setCutFrom}
        onStopChange={setCutTo}
      />

      <ServerDownloadButton
        // Force re-render on format and quality change
        key={convertKey}
        convert={true}
        text={
          cutFile
            ? t('common:conv_to_cut', { format: getFormatLabel(format) })
            : t('common:conv_to', { format: getFormatLabel(format) })
        }
        info={info}
        format={format}
        quality={qualitySupported ? quality : null}
        cutFrom={cutFile ? cutFrom : null}
        cutTo={cutFile ? cutTo : null}
        initialStatus={t('common:click_to_start_conv')}
        className="w-full mt-4 cursor-pointer"
      />

      {/* <Button
        as="a"
        color="success"
        href={getStartedLink2}
        target="_blank"
        rel="nofollow noreferrer noopener"
        className="relative w-full mt-4 font-bold"
        onClick={() => trackPromotion('btn-convert')}
      >
        {t('videoproc:convert_formats')}
        <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
        </span>
      </Button>

      <Button
        as="a"
        color="success"
        href={compressLink}
        target="_blank"
        rel="nofollow noreferrer noopener"
        className="w-full mt-4 font-bold"
        onClick={() => trackPromotion('btn-comp2')}
      >
        {t('videoproc:edit')}
      </Button> */}
    </div>
  );
}
