import React from 'react';

import { formatBytes, getQuality, formatResolution } from 'lib/utils';
import Select from 'components/Select';

const GROUPS = {
  video: { label: 'Video', icon: '📼' },
  audio: { label: 'Audio', icon: '🎧' },
  videoOnly: { label: 'Video Only', icon: '🔇' },
};

function FormatGroup({ name, formats }) {
  return (
    <optgroup label={GROUPS[name].label}>
      {formats.map((f) => {
        const quality = getQuality(f);
        const resolution = formatResolution(f);
        const filesize = f.filesize ? formatBytes(f.filesize) : '';
        const filesizeApprox = f.filesize_approx
          ? formatBytes(f.filesize_approx)
          : '';
        // Audio bitrate
        const abr = f.abr ? `${Math.round(f.abr)}Kbps` : '';
        // Video bitrate
        const vbr = f.vbr ? `${Math.round(f.vbr)}Kbps` : '';
        // Bitrate
        const tbr = f.tbr && !f.abr && !f.vbr ? `${Math.round(f.tbr)}Kbps` : '';
        // Audio sampling rate
        const asr = f.asr ? `${f.asr}Hz` : '';
        // Frames per second
        const fps = f.fps ? `${Math.round(f.fps)}fps` : '';

        return (
          <option key={f.format_id} value={f.format_id}>
            {/* {f.isDownloadable ? '⬇ - ' : '⏬ - '} */}
            {GROUPS[name].icon} - {f.ext?.toUpperCase()}
            {quality ? ` - ${quality}` : ''}
            {resolution ? ` - (${resolution})` : ''}
            {abr ? ` - ${abr}` : ''}
            {vbr ? ` - ${vbr}` : ''}
            {tbr ? ` - ${tbr}` : ''}
            {asr ? ` - ${asr}` : ''}
            {fps ? ` - ${fps}` : ''}
            {filesize ? ` - (${filesize})` : ''}
            {filesizeApprox ? ` - (~${filesizeApprox})` : ''}
          </option>
        );
      })}
    </optgroup>
  );
}

export default function FormatSelect({ formats, value, onChange, ...props }) {
  return (
    <Select value={value} onChange={(e) => onChange(e.target.value)} {...props}>
      {Object.entries(formats).map(([name, options]) =>
        options.length > 0 ? (
          <FormatGroup key={name} name={name} formats={options} />
        ) : null
      )}
    </Select>
  );
}
