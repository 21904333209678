import {
  ADS_ENABLED,
  AD_URL,
  AD_OPEN_INTERVAL,
  AD_PWA_OPEN_INTERVAL,
} from 'config';
import { openURL } from 'lib/url';
import { isStandalone } from 'lib/devices';

export function openAd() {
  if (ADS_ENABLED) {
    return openURL(
      AD_URL,
      isStandalone ? AD_PWA_OPEN_INTERVAL : AD_OPEN_INTERVAL
    );
  }

  return false;
}
