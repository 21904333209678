import React from 'react';
import { Switch } from '@headlessui/react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { classNames } from 'lib/helpers';

export default function Toggle({
  enabled = false,
  onChange,
  srLabel,
  className,
  children,
}) {
  const { i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';

  return (
    <Switch.Group
      as="div"
      className={classNames('flex items-center', className)}
    >
      <Switch
        checked={enabled}
        onChange={onChange}
        className={classNames(
          enabled ? 'bg-blue-600' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
        )}
      >
        {srLabel && <span className="sr-only">{srLabel}</span>}
        <span
          aria-hidden="true"
          className={classNames(
            enabled
              ? isRTL
                ? '-translate-x-5'
                : 'translate-x-5'
              : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
      {children && (
        <Switch.Label as="span" className={isRTL ? 'mr-3' : 'ml-3'}>
          <span className="text-sm font-medium text-gray-900 cursor-pointer">
            {children}
          </span>
        </Switch.Label>
      )}
    </Switch.Group>
  );
}
