export class APIError extends Error {
  constructor({ status, code, message, retry }) {
    super(message);

    this.name = 'APIError';
    this.status = status;
    this.code = code;
    this.retry = retry;
  }
}

export class RequestTimeOutError extends APIError {
  constructor(message) {
    super({ code: 1, message });
    this.name = 'RequestTimeOutError';
  }
}

export function isCancelError(error) {
  return error?.name === 'AbortError';
}
