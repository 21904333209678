import React from 'react';

export default function useAbortController() {
  const [controller] = React.useState(() => new AbortController());

  // Abort the request on component unmount
  React.useEffect(() => () => controller.abort(), [controller]);

  return controller;
}
