import { API_ENDPOINT } from 'config';
import { APIError, RequestTimeOutError } from 'lib/errors';

export const TaskStatePending = 'pending';
export const TaskStateActive = 'active';
export const TaskStateRetry = 'retry';
export const TaskStateProgress = 'progress';
export const TaskStateCompleted = 'completed';
export const TaskStateFailed = 'failed';

async function handleResponse(response) {
  const json = await response.json();

  // HTTP errors.
  if (!response.ok) {
    throw new APIError({
      status: response.status,
      code: json.code,
      message: json.message,
      retry: json.retry,
    });
  }

  return json;
}

export async function apiRequest(path, { headers, ...options } = {}) {
  // Fetch will only reject on network errors (connection, security, DNS...)
  const response = await fetch(`${API_ENDPOINT}${path}`, {
    headers: { Accept: 'application/json', ...headers },
    ...options,
  });

  return handleResponse(response);
}

export function apiPost(path, { data, ...options } = {}) {
  return apiRequest(path, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
    ...options,
  });
}

// Poll the task status
export function pollTask(
  href,
  { timeout = Infinity, interval = 1500, onProgress, ...fetchOptions } = {}
) {
  // By default there's no timeout
  const endTime = Number(new Date()) + timeout;

  const poll = (resolve, reject) => {
    apiRequest(href, fetchOptions)
      .then((response) => {
        // If the task succeeded resolve
        if (response.state === TaskStateCompleted) {
          // If we have a result
          if (response.result) {
            // Check if we have a "url", "formats" array or "files" array
            if (
              // Check if we have a "url" (Task: info)
              response.result.url ||
              // Check if we have a "formats" array and is not empty (Task: info)
              (response.result.formats &&
                response.result.formats.length >= 1) ||
              // Check if we have a "files" array (Task: download, convert)
              response.result.files ||
              // Check if we have an "entries" array (Note: Playlists) (Task: info)
              response.result.entries
            ) {
              // Resolve if we have data to display
              return resolve(response);
            }
          }

          // Otherwise if we don't have data to display
          // Poll again if the timeout hasn't elapsed
          if (Number(new Date()) < endTime) {
            setTimeout(poll, interval, resolve, reject);
          } else {
            // The task succeeded but without a result to display (url or formats)
            reject(new Error('Task succeeded but without a result'));
          }
        }
        // If the task failed reject
        else if (response.state === TaskStateFailed) {
          // Task errors.
          reject(
            new APIError({
              code: response.error.code,
              message: response.error.message,
              retry: response.error.retry,
            })
          );
        }
        // If the task is reporting progress info
        else if (response.state === TaskStateProgress) {
          // Report progress
          onProgress?.(response);

          // Poll again if the timeout wasn't reached.
          if (Number(new Date()) < endTime) {
            setTimeout(poll, interval, resolve, reject);
          } else {
            reject(new RequestTimeOutError(`Polling timed out for ${href}`));
          }
        }
        // If the condition isn't met but the timeout hasn't elapsed, poll again
        else if (Number(new Date()) < endTime) {
          setTimeout(poll, interval, resolve, reject);
        }
        // Didn't match and too much time, reject!
        else {
          reject(new RequestTimeOutError(`Polling timed out for ${href}`));
        }
      })
      .catch(reject);
  };

  return new Promise(poll);
}

// Enqueue a task to be processed by the workers
export async function enqueueTask(data, options) {
  // Throws an APIError on HTTP errors (Validation errors, server errors...).
  const res = await apiPost('/tasks', { data });

  if (res.state === TaskStatePending && res.href) {
    // Throws an APIError on task failure.
    return pollTask(res.href, options);
  }

  // Task is done (Cached).
  return res;
}

// Retry an API request on certain conditions before displaying an error
export function retryRequest(
  request,
  {
    // Number of times to retry
    times = 1,
    // Time to retry after (timeout x attempt number)
    timeout = 2000,
    // Callback to be called on each retry with the retry data
    onRetry,
    // Filter to specify on what errors to retry
    // Check application specific errors
    shouldRetry = (error) => {
      // Retry only on server errors.
      // Previously we were retrying based on error.retry.
      return [500, 502, 503, 504].includes(error.status);
    },
  } = {}
) {
  // Retry attempt number
  let attempt = 0;

  const retryAttempt = (resolve, reject) => {
    request()
      .then(resolve)
      .catch((error) => {
        if (attempt++ < times && shouldRetry(error)) {
          const delay = timeout * attempt;

          onRetry?.({ attempt, delay });
          setTimeout(retryAttempt, delay, resolve, reject);
        } else {
          reject(error);
        }
      });
  };

  return new Promise(retryAttempt);
}

export async function sendTask({
  data,
  onSuccess,
  onError,
  // "pollTask" options
  taskOptions,
  retryOptions,
}) {
  try {
    const response = await retryRequest(
      // Function to be retried
      () =>
        enqueueTask(data, {
          // Send the API request, 15 minutes timeout
          timeout: 900000,
          ...taskOptions,
        }),
      // Retry options
      {
        times: 1,
        timeout: 3000,
        onRetry: ({ attempt, delay }) => {
          console.log(`Retrying... (Attempt: ${attempt}, Delay: ${delay})`);
        },
        ...retryOptions,
      }
    );

    onSuccess?.(response);
  } catch (error) {
    onError?.(error);
  }
}

// function replaceURL(url) {
//   if (url.includes('vimeo.com/')) {
//     return url.replace('https://', 'http://');
//   }

//   return url;
// }

export function fetchInfo(url, { onSuccess, ...options }) {
  // TODO: temporary fix for vimeo.
  // url = replaceURL(url);

  return sendTask({
    data: { type: 'info', url },
    onSuccess: ({ result }) => onSuccess(result),
    ...options,
  });
}
