export function localStorageSet(key, value) {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    console.log('localStorage.setItem Error: ', error);
  }
}

export function localStorageGet(key) {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    console.log('localStorage.getItem Error: ', error);
  }

  return null;
}

export function localStorageRemove(key) {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.log('localStorage.removeItem Error: ', error);
  }
}

export function setItem(key, value, ttl) {
  const data = {
    value,
    // TTL must be in milliseconds (eg: 5 minutes = 5 * 60 * 1000)
    expiry: ttl != null ? new Date().getTime() + ttl : null,
  };

  localStorageSet(key, JSON.stringify(data));
}

export function getItem(key) {
  const data = localStorageGet(key);

  // If the item doesn't exist, return null
  if (!data) {
    return null;
  }

  const item = JSON.parse(data);

  // Compare the expiry time of the item with the current time
  if (item.expiry && new Date().getTime() > item.expiry) {
    // If the item has expired, delete the item from storage and return null
    localStorageRemove(key);

    return null;
  }

  return item.value;
}
