import React from 'react';

import Toggle from 'components/Toggle';
import RangePicker from 'components/RangePicker';

export default function DurationRangePicker({
  label,
  enabled,
  onChange,
  className,
  ...props
}) {
  return (
    <div className={className}>
      <Toggle srLabel={label} enabled={enabled} onChange={onChange}>
        {label}
      </Toggle>

      <RangePicker show={enabled} className="mt-3" {...props} />
    </div>
  );
}
