// Conversion video formats
export const videoFormats = ['mp4', 'webm', 'avi', 'mov', 'mkv', 'flv'];

// Conversion audio formats
// All the formats other than wav support CBR and VBR except opus which supports only CBR
export const audioFormats = [
  'best',
  'mp3',
  'm4a',
  'aac',
  'flac',
  'wav',
  'opus',
  'vorbis',
];
// Variable bitrate (VBR)
export const audioVBR = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

// Constant bitrate (CBR)
export const audioCBR = ['320', '256', '192', '128', '96', '64', '32'];

// Get the label for a format if it has one
export function getFormatLabel(format) {
  if (format === 'best') {
    return 'Best Quality Audio';
  } else if (format === 'vorbis') {
    return 'Ogg Vorbis';
  }

  return format.toUpperCase();
}

// Get the label for the quality if it has one
export function getQualityLabel(quality) {
  if (quality === '0' || quality === '320') {
    return 'High Quality';
  } else if (quality === '5' || quality === '128') {
    return 'Medium Quality';
  } else if (quality === '9' || quality === '32') {
    return 'Low Quality';
  }

  return null;
}

export function formatBitrate(n) {
  if (Number(n) > 10) {
    return n + ' Kbps';
  }

  return n;
}

// Show the quality select menu for specific formats
export function isQualitySupported(format) {
  return ['mp3', 'flac', 'm4a', 'aac', 'opus', 'vorbis'].includes(format);
}

// Formats that support VBR quality
export function isVBRSupported(format) {
  return format !== 'opus';
}
