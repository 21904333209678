import React from 'react';

import { fetchInfo } from 'lib/api';
import Button from 'components/Button';
import ResultDisplay from 'components/ResultDisplay';
import useHandleErrors from 'hooks/useHandleErrors';
import useAbortController from 'hooks/useAbortController';

export default function FetchInfo({ info, onInfoUpdate }) {
  const [result, setResult] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleErrors = useHandleErrors();

  const hasResult = result.length > 0;

  React.useEffect(() => {
    // Check if the result is the full info object of the "info" prop
    // This is applicable to playlist items, used to replace the incomplete info
    const shouldUpdate =
      result.length === 1 && info.id && result[0].id === info.id;

    if (shouldUpdate) {
      onInfoUpdate?.(result[0]);
    }
  }, [result, info.id, onInfoUpdate]);

  const { signal } = useAbortController();

  return (
    <div>
      {hasResult ? (
        // Display nested playlists or multi video results
        <ResultDisplay result={result} />
      ) : (
        <Button
          isLoading={isLoading}
          onClick={async () => {
            setIsLoading(true);

            await fetchInfo(info.url, {
              onSuccess: setResult,
              onError: handleErrors,
              taskOptions: { signal },
            });

            setIsLoading(false);
          }}
        >
          Fetch Data
        </Button>
      )}
    </div>
  );
}
