import { useTranslation } from 'gatsby-plugin-react-i18next';

import { useToasts } from 'components/Toast';
import { APIError, isCancelError } from 'lib/errors';

export default function useHandleErrors() {
  const { t } = useTranslation();

  const { addToast } = useToasts();

  return function handleErrors(error) {
    if (error instanceof APIError) {
      addToast({
        title: t('common:errors.error'),
        content: t(`common:errors.${error.code}`),
        appearance: 'error',
        autoDismiss: true,
      });
    } else {
      if (isCancelError(error)) {
        addToast({
          title: t('common:errors.cancelled'),
          content: t('common:errors.req_cancelled'),
          appearance: 'error',
          autoDismiss: true,
        });
      } else {
        addToast({
          title: t('common:errors.conn_error'),
          content: t('common:errors.conn_error_desc'),
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
  };
}
