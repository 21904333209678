import React from 'react';

export default function Divider() {
  return (
    <div className="relative max-w-4xl mx-auto">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t-2 border-gray-100"></div>
      </div>
    </div>
  );
}
