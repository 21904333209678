import React from 'react';
import { useQueryParams, StringParam } from 'use-query-params';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

import { DefaultStringParam } from 'lib/query-params';
import { fetchInfo } from 'lib/api';
import useHandleErrors from 'hooks/useHandleErrors';
import { getURL, isDisabledSite } from 'lib/url';
import { openAd } from 'lib/ads';
import Link from 'components/Link';
import Button from 'components/Button';
import Section from 'components/Section';
import ResultModal from 'components/ResultModal';
import { useToasts } from 'components/Toast';
// import Promotion from 'components/misc/Promotion';

// import video from 'data/vimeo/video.json';
// import shortVideo from 'data/vimeo/short-video.json';
// import videoSubtitles from 'data/vimeo/video-subtitles.json';
// import playlist from 'data/vimeo/playlist-process.json';

// const r = [video];
// const r = [shortVideo];
// const r = [videoSubtitles];
// const r = playlist;

export default function MainHero({ title, description, aside }) {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';

  const { addToast } = useToasts();

  const [query, setQuery] = useQueryParams({
    url: DefaultStringParam,
    // Share target API param
    text: StringParam,
    // YouTube video and playlist IDs params
    v: StringParam,
    list: StringParam,
  });

  const { url } = query;
  const setUrl = (url) => setQuery({ url });

  const handleErrors = useHandleErrors();

  React.useEffect(() => {
    // If we don't have a "url" param, check the other params to get the URL
    if (!url) {
      const newUrl = getURL({
        query,
        onMissingURL: () => {
          addToast({
            title: t('common:errors.no_links'),
            content: t('common:errors.no_links_desc'),
            appearance: 'error',
            autoDismiss: true,
          });
        },
      });

      if (newUrl) {
        setQuery({
          url: newUrl,
          text: undefined,
          v: undefined,
          list: undefined,
        });
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [result, setResult] = React.useState([]);
  const [displayResult, setDisplayResult] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const getInfo = async () => {
    if (!url.trim()) {
      addToast({
        title: t('common:errors.missing_url'),
        content: t('common:errors.enter_link'),
        appearance: 'error',
        autoDismiss: true,
      });

      return;
    }

    if (isDisabledSite(url)) {
      addToast({
        title: t('common:errors.not_supported'),
        content: t('common:errors.2000'),
        appearance: 'error',
        autoDismiss: false,
      });

      return;
    }

    setIsLoading(true);

    await fetchInfo(url, {
      onSuccess: (result) => {
        setResult(result);
        setDisplayResult(true);
      },
      onError: handleErrors,
    });

    setIsLoading(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    getInfo();

    openAd();
  };

  return (
    <Section>
      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
        <div
          className={`sm:text-center md:max-w-2xl md:mx-auto lg:mx-0 lg:col-span-8 ${
            isRTL ? 'lg:text-right' : 'lg:text-left'
          }`}
        >
          <h1>
            <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl">
              {title}
            </span>
          </h1>
          <p className="mt-3 text-base text-gray-700 sm:mt-6 sm:text-xl lg:text-lg xl:text-xl">
            {description}
          </p>

          <div
            className={`mt-8 sm:mt-10 sm:mx-auto sm:text-center lg:mx-0 ${
              isRTL ? 'lg:text-right' : 'lg:text-left'
            }`}
          >
            <form method="POST" className="mt-3 sm:flex" onSubmit={onSubmit}>
              <label htmlFor="url" className="sr-only">
                {t('common:video_link')}
              </label>
              <input
                id="url"
                type="text"
                name="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                onFocus={(e) => e.target.select()}
                disabled={isLoading}
                className="block w-full py-3 text-base rounded-md placeholder-gray-500 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:flex-1 border-gray-300"
                placeholder={t('common:paste_link')}
              />
              <Button
                size="xl"
                className={`mt-3 sm:mt-0 w-full sm:w-auto ${
                  isRTL ? 'sm:mr-3' : 'sm:ml-3'
                }`}
                isLoading={isLoading}
              >
                {t('start')}
              </Button>
            </form>
            <p className="mt-3 text-sm text-gray-500">
              <Trans
                i18nKey="common:main_disclaimer"
                components={[
                  <Link
                    href="/terms"
                    className="font-medium text-gray-900 underline"
                  />,
                ]}
              />
            </p>
          </div>

          {/* <Promotion className="mt-16 hidden lg:block" /> */}

          <ResultModal
            size="3xl"
            result={result}
            open={displayResult}
            setOpen={setDisplayResult}
          />
        </div>

        <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-4">{aside}</div>

        {/* <Promotion className="mt-16 lg:hidden" /> */}
      </div>
    </Section>
  );
}
