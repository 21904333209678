import React from 'react';

/**
 * Helper hook to detect if the component is being rendered on the client.
 *
 * To be used to force component hydrate on the client (e.g. with a key attr).
 */
export default function useIsClient() {
  const [isClient, setClient] = React.useState(false);

  React.useEffect(() => {
    setClient(true);
  }, []);

  return isClient;
}
