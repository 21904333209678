import React from 'react';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

// import Button from 'components/Button';
import FormatSelect from 'components/FormatSelect';
import DurationRangePicker from 'components/DurationRangePicker';
import ServerDownloadButton from 'components/ServerDownloadButton';
import useCutFileState from 'hooks/useCutFileState';
// import { trackPromotion } from 'lib/analytics';
// import { compressLink } from 'lib/videoproc';

// Check if 2 video and audio extensions are compatible for merge
// Non compatible extensions will be merged into an "mkv" container
// See: YoutubeDL.process_info ("compatible_formats" nested function)
function areMergeCompatible(videoExt, audioExt) {
  if (videoExt && audioExt) {
    const compatExts = [
      ['mp3', 'mp4', 'm4a', 'm4p', 'm4b', 'm4r', 'm4v', 'ismv', 'isma'],
      // Only webm video and audio can be merged together into webm
      ['webm'],
    ];

    for (const exts of compatExts) {
      if (exts.includes(videoExt) && exts.includes(audioExt)) {
        return true;
      }
    }
  }

  return false;
}

export default function MergePanel({ info, videoFormats, audioFormats }) {
  const { t } = useTranslation();

  const [videoFormatID, setVideoFormatID] = React.useState(
    videoFormats[0]?.format_id
  );

  const [audioFormatID, setAudioFormatID] = React.useState(
    audioFormats[0]?.format_id
  );

  const selectedVideoFormat = videoFormats.find(
    ({ format_id }) => format_id === videoFormatID
  );

  const selectedAudioFormat = audioFormats.find(
    ({ format_id }) => format_id === audioFormatID
  );

  // Are the formats compatible
  // Non compatible formats will be merged into an "mkv" container
  const areCompatible = areMergeCompatible(
    selectedVideoFormat.ext,
    selectedAudioFormat.ext
  );

  const mergeFormatId = `${selectedVideoFormat.format_id}+${selectedAudioFormat.format_id}`;

  const { cutFile, setCutFile, cutFrom, setCutFrom, cutTo, setCutTo } =
    useCutFileState({ info });

  const mergeKey = `${mergeFormatId}${
    cutFile ? '-' + cutFrom + '-' + cutTo : ''
  }`;

  return (
    <div>
      <FormatSelect
        label={t('common:labels.select_video_format')}
        id="merge-video-format"
        formats={{ videoOnly: videoFormats }}
        value={videoFormatID}
        onChange={setVideoFormatID}
      />

      <FormatSelect
        label={t('common:labels.select_audio_format')}
        id="merge-audio-format"
        formats={{ audio: audioFormats }}
        value={audioFormatID}
        onChange={setAudioFormatID}
        className="mt-4"
      />

      <DurationRangePicker
        className="mt-4"
        label={t('common:labels.cut_file')}
        enabled={cutFile}
        onChange={setCutFile}
        duration={info.duration ?? 86400}
        start={cutFrom}
        stop={cutTo}
        onStartChange={setCutFrom}
        onStopChange={setCutTo}
      />

      {!areCompatible && (
        <p className="text-sm mt-4 text-gray-700">
          <Trans
            i18nKey="common:not_compatible"
            values={{ format: 'MKV' }}
            components={[
              <span className="font-bold" />,
              <span className="text-blue-500" />,
            ]}
          />
        </p>
      )}

      <ServerDownloadButton
        // Force re-render on format change
        key={mergeKey}
        text={
          cutFile ? t('common:merge_formats_cut') : t('common:merge_formats')
        }
        info={info}
        format={mergeFormatId}
        cutFrom={cutFile ? cutFrom : null}
        cutTo={cutFile ? cutTo : null}
        initialStatus={t('common:click_to_start_merge')}
        className="w-full mt-4 cursor-pointer"
      />

      {/* <Button
        as="a"
        color="success"
        size="sm"
        href={compressLink}
        target="_blank"
        rel="nofollow noreferrer noopener"
        className="w-full mt-4 font-bold"
        onClick={() => trackPromotion('btn-comp3')}
      >
        {t('videoproc:edit')}
      </Button> */}
    </div>
  );
}
