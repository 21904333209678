import React from 'react';
import { useQueryParams, StringParam } from 'use-query-params';

import { isQualitySupported, isVBRSupported } from 'lib/convert-utils';

const DEFAULT_FORMAT = 'mp3';
const DEFAULT_QUALITY = '5';

export default function useConvertState({ cleanup = true } = {}) {
  const [query, setQuery] = useQueryParams({
    format: StringParam,
    quality: StringParam,
  });

  const { format = DEFAULT_FORMAT, quality = DEFAULT_QUALITY } = query;

  const qualitySupported = isQualitySupported(format);
  const VBRSupported = isVBRSupported(format);

  React.useEffect(() => {
    // If the selected format does not support VBR and the quality
    // is already set to VBR then change it
    if (!VBRSupported && Number(quality) < 10) {
      setQuery({ quality: '128' });
    }
  }, [VBRSupported, quality, setQuery]);

  React.useEffect(() => {
    // Remove the quality param if not supported
    if (!qualitySupported) {
      setQuery({ quality: undefined });
    }
  }, [qualitySupported, setQuery]);

  React.useEffect(() => {
    // Remove the query params of this component on unmount
    return () => {
      if (cleanup) {
        setQuery({ format: undefined, quality: undefined });
      }
    };
  }, [setQuery, cleanup]);

  return {
    format,
    setFormat: (format) => setQuery({ format }),
    quality,
    setQuality: (quality) => setQuery({ quality }),
    qualitySupported,
    VBRSupported,
  };
}
