import { isWin, isMac, isiOS, isDesktop } from 'lib/devices';

export function getDownloadLink() {
  if (isWin) {
    return 'https://www.videoproc.com/download/videoproc-dl.exe';
  } else if (isMac && !isiOS) {
    return 'https://www.videoproc.com/download/videoproc-dl.dmg';
  } else {
    return 'https://www.videoproc.com/install/videoproc-download-mobile.htm?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo-fd&ttref=stvideo-fd';
  }
}

export const downloadLink = getDownloadLink();

export const licenseLink =
  'https://www.videoproc.com/event/holiday-offer.htm?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo-full&ttref=stvideo-full';

function makeGetStartedLink(campaign) {
  if (isDesktop) {
    return `https://www.videoproc.com/video-converting-software/feature-online-vs-videoproc.htm?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=${campaign}&ttref=${campaign}`;
  } else {
    return `https://www.videoproc.com/video-converting-software/feature-online-vs-videoproc.htm?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=${campaign}&ttref=${campaign}`;
  }
}

// SideBox get started link + Header Desktop App link
export const getStartedLink = makeGetStartedLink('stvideo-home');

// ConvertPanel link
export const getStartedLink2 = makeGetStartedLink('stvideo-conv-edit');

// Error toast link
export const errorLink = makeGetStartedLink('stvideo-fix-error');

// Link used on the Vimeo downloader page
export const vimeoLink =
  'https://www.videoproc.com/download-record-video/download-private-vimeo-videos.htm?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo-home-seo&ttref=stvideo-home-seo';

// ConvertPanel + DownloadPanel + MergePanel compress buttons link
export const compressLink =
  'https://www.videoproc.com/video-converting-software/feature-video-compressing.htm?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo-dcompress&ttref=stvideo-dcompress';

// SideBox3 + Header editor i18n links
export function getEditorLink(lang = 'en', label) {
  const LINKS = {
    en: `https://www.videoproc.com/video-editing-software/?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=${label}&ttref=${label}`,
    de: `https://www.videoproc.com/de/video-editing-software/?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=${label}&ttref=${label}`,
    es: `https://www.videoproc.com/es/video-editing-software/?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=${label}&ttref=${label}`,
    fr: `https://www.videoproc.com/fr/video-editing-software/?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=${label}&ttref=${label}`,
    it: `https://www.videoproc.com/it/video-editing-software/?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=${label}&ttref=${label}`,
  };

  return LINKS[lang] ?? LINKS['en'];
}

// DownloadPanel link 1
export function getDlLink(lang = 'en') {
  if (isDesktop) {
    const LINKS = {
      en: 'https://www.videoproc.com/video-converting-software/?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo-d4k&ttref=stvideo-d4k',
      de: 'https://www.videoproc.com/de/video-converting-software/?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo-d4k&ttref=stvideo-d4k',
      es: 'https://www.videoproc.com/es/video-converting-software/?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo-d4k&ttref=stvideo-d4k',
      fr: 'https://www.videoproc.com/fr/video-converting-software/?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo-d4k&ttref=stvideo-d4k',
      it: 'https://www.videoproc.com/it/video-converting-software/?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo-d4k&ttref=stvideo-d4k',
    };

    return LINKS[lang] ?? LINKS['en'];
  } else {
    return 'https://www.videoproc.com/video-converting-software/feature-online-vs-videoproc.htm?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo-d4k&ttref=stvideo-d4k';
  }
}

// Desktop App link (header), get started buttons on SideBox.
export function getAppLink(lang = 'en', place) {
  const LINKS = {
    en: `https://www.videoproc.com/video-converting-software/?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo-home&ttref=stvideo-home`,
    de: `https://www.videoproc.com/de/video-converting-software/?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo-home&ttref=stvideo-home`,
    fr: `https://www.videoproc.com/fr/video-converting-software/?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo-home&ttref=stvideo-home`,
    es: `https://www.videoproc.com/es/video-converting-software/?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo-home&ttref=stvideo-home`,
    it: `https://www.videoproc.com/it/video-converting-software/?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo-home&ttref=stvideo-home`,
  };

  if (lang === 'pt' && place === 'convert') {
    return 'https://www.videoproc.com/video-converting-software/feature-video-converting-pt.htm?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo-home&ttref=stvideo-home';
  }

  return LINKS[lang] ?? LINKS['en'];
}

export function getAILink(lang = 'en') {
  const LINKS = {
    en: 'https://www.videoproc.com/video-converting-software/feature-ai-super-resolution.htm?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo-home-ai&ttref=stvideo-home-ai',
    de: 'https://www.videoproc.com/de/video-converting-software/feature-ai-super-resolution.htm?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo-home-ai&ttref=stvideo-home-ai',
    fr: 'https://www.videoproc.com/fr/video-converting-software/feature-ai-super-resolution.htm?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo-home-ai&ttref=stvideo-home-ai',
    es: 'https://www.videoproc.com/es/video-converting-software/feature-ai-super-resolution.htm?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo-home-ai&ttref=stvideo-home-ai',
    it: 'https://www.videoproc.com/it/video-converting-software/feature-ai-super-resolution.htm?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo-home-ai&ttref=stvideo-home-ai',
  };

  return LINKS[lang] ?? LINKS['en'];
}
