import React from 'react';
import ReactPaginate from 'react-paginate';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

import InfoDisplay from 'components/InfoDisplay';

function getPageEntries({ entries, pageNumber, pageSize }) {
  const start = (pageNumber - 1) * pageSize;
  const end = start + pageSize;
  return entries.slice(start, end);
}

export default function ResultDisplay({
  result,
  pageSize: initialPageSize = 2,
}) {
  const { t } = useTranslation();

  const [pageSize, setPageSize] = React.useState(initialPageSize);
  const [search, setSearch] = React.useState('');
  const query = search.toLowerCase().trim();

  const [pageNumber, setPageNumber] = React.useState(1);

  const totalItems = result.length;
  const hasMultipleItems = totalItems > 1;

  // Entries with the original indexes
  // Set the original index on the object to preserve the entry number
  const numberedEntries = React.useMemo(() => {
    return result.map((info, index) => ({
      ...info,
      index,
    }));
  }, [result]);

  const filteredEntries = React.useMemo(() => {
    if (query) {
      // Create a list of search keywords
      const queryList = query.split(' ');

      return numberedEntries.filter((item) => {
        // Search the description or the URL if it doesn't have a title
        const text = item.title ?? item.description ?? item.url;
        // The entry matches if it contains all the keywords
        return queryList.every((keyword) => {
          return text.toLowerCase().includes(keyword);
        });
      });
    }

    return numberedEntries;
  }, [query, numberedEntries]);

  const pageEntries = getPageEntries({
    entries: filteredEntries,
    pageNumber,
    pageSize,
  });

  const totalResults = filteredEntries.length;
  const hasResults = totalResults > 0;

  const totalPages = Math.ceil(totalResults / pageSize);

  const from = (pageNumber - 1) * pageSize + 1;
  const to = Math.min(from + pageSize - 1, totalResults);

  const onPageChange = ({ selected }) => {
    setPageNumber(selected + 1);
  };

  // Reset the page number on search and page size change
  React.useEffect(() => {
    setPageNumber(1);
  }, [search, pageSize]);

  return (
    <div>
      {hasMultipleItems && (
        <>
          <h2 className="text-xl leading-6 font-bold text-gray-900 px-4 sm:px-6 pb-4">
            <Trans
              i18nKey="common:found_items"
              values={{ total: totalItems }}
              components={[<span className="text-blue-500" />]}
            />
          </h2>

          {totalItems > 2 && (
            <div className="pb-6 px-4 sm:px-6">
              <div className="sm:flex sm:space-x-4">
                <div className="flex-1">
                  <label
                    htmlFor="search"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t('common:search')}
                  </label>
                  <div className="mt-1">
                    <input
                      id="search"
                      type="search"
                      className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder={t('common:search_items')}
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>

                <div className="mt-2 sm:mt-0">
                  <label
                    htmlFor="page-size"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t('common:page_size')}
                  </label>
                  <select
                    id="page-size"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                    value={pageSize}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                  >
                    <option>2</option>
                    <option>5</option>
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                    <option>100</option>
                  </select>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {hasResults ? (
        <div className={hasMultipleItems ? 'space-y-4' : ''}>
          {pageEntries.map((info, index) => (
            <InfoDisplay
              key={`${index}-${info.id}`}
              number={hasMultipleItems ? info.index + 1 : null}
              info={info}
              className={
                hasMultipleItems
                  ? 'bg-gray-50 border-t border-b border-gray-200'
                  : ''
              }
            />
          ))}
        </div>
      ) : (
        <div className="px-4 sm:px-6">
          <div className="rounded-md bg-blue-50 p-4">
            <p className="text-sm text-blue-700">
              {t('common:search_no_match')}
            </p>
          </div>
        </div>
      )}

      {totalPages > 1 && (
        <nav
          className="pt-4 px-4 sm:px-6 flex items-center justify-between"
          aria-label="Pagination"
        >
          <div className="hidden sm:block">
            <p className="text-sm text-gray-700 space-x-1">
              <Trans
                i18nKey="common:search_results_info"
                values={{ from, to, total: totalResults }}
                components={[
                  <span className="font-bold" />,
                  <span className="font-bold" />,
                  <span className="font-bold" />,
                ]}
              />
            </p>
          </div>

          <div>
            <ReactPaginate
              previousClassName="rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              previousLinkClassName="relative inline-flex items-center px-2 py-2 focus:outline-none"
              previousLabel={
                <>
                  <span className="sr-only">{t('common:previous')}</span>
                  {/* <!-- Heroicon name: solid/chevron-left --> */}
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </>
              }
              nextClassName="rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              nextLinkClassName="relative inline-flex items-center px-2 py-2 focus:outline-none"
              nextLabel={
                <>
                  <span className="sr-only">{t('common:next')}</span>
                  {/* <!-- Heroicon name: solid/chevron-right --> */}
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </>
              }
              breakLabel="..."
              breakClassName="border border-gray-300 bg-white text-sm font-medium text-gray-700 select-none"
              breakLinkClassName="relative inline-flex items-center px-4 py-2 focus:outline-none"
              forcePage={pageNumber - 1}
              pageCount={totalPages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={onPageChange}
              containerClassName="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
              pageClassName="border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 select-none"
              pageLinkClassName="relative inline-flex items-center px-4 py-2 focus:outline-none"
              activeClassName="bg-gray-50"
              disabledClassName="bg-gray-50"
            />
          </div>
        </nav>
      )}
    </div>
  );
}
