import React from 'react';
import { Tab, Tabs as ReactTabs, TabList } from 'react-tabs';
import { useTranslation } from 'gatsby-plugin-react-i18next';
export { TabPanel } from 'react-tabs';

export function Tabs({ tabs, activeTabIndex = 0, children, className }) {
  const { i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';

  const [tabIndex, setTabIndex] = React.useState(activeTabIndex);

  function tabClassName(index) {
    if (tabIndex === index) {
      return 'border-blue-500 text-blue-600';
    }

    return 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300';
  }

  return (
    <div className={className}>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          value={tabIndex}
          onChange={(e) => setTabIndex(Number(e.target.value))}
        >
          {tabs.map((label, index) => (
            <option key={index} value={index}>
              {label}
            </option>
          ))}
        </select>
      </div>

      <ReactTabs selectedIndex={tabIndex} onSelect={setTabIndex}>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            {/* <!-- Current: "border-blue-500 text-blue-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" --> */}
            <TabList
              className={`-mb-px flex space-x-8 ${
                isRTL ? 'space-x-reverse' : ''
              }`}
              aria-label="Tabs"
            >
              {tabs.map((label, index) => (
                <Tab
                  key={index}
                  className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer focus:outline-none ${tabClassName(
                    index
                  )}`}
                  aria-current="page"
                >
                  {label}
                </Tab>
              ))}
            </TabList>
          </div>
        </div>

        <div className="pt-2">{children}</div>
      </ReactTabs>
    </div>
  );
}
