import React from 'react';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

import { getResultType, formatSeconds, getThumbnail } from 'lib/utils';
import VideoDownload from 'components/VideoDownload';
import FetchInfo from 'components/FetchInfo';

export default function InfoDisplay({ info: originalInfo, number, ...props }) {
  const { i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';

  const [info, setInfo] = React.useState(originalInfo);
  const resultType = getResultType(info);
  // The result will be one of "video", "url", "url_transparent"
  const isVideo = resultType === 'video';

  const thumbnail = getThumbnail(info);
  const thumbMargin = isRTL ? 'sm:ml-4' : 'sm:mr-4';

  return (
    <div {...props}>
      <div className="py-5">
        <h3 className="text-lg leading-6 font-bold text-gray-900">
          {number && <span className="text-blue-500">{number}. </span>}
          {info.title ?? info.url}
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-700">
          {info.uploader && (
            <Trans
              i18nKey="common:uploader_name"
              values={{ name: info.uploader }}
              components={[<span className="font-bold" />]}
            />
          )}
          {info.duration && (
            <>
              {' - '}
              <Trans
                i18nKey="common:duration_info"
                values={{ duration: formatSeconds(info.duration) }}
                components={[<span className="font-bold" />]}
              />
            </>
          )}
        </p>
      </div>

      <div className="border-t border-gray-200 py-5">
        <div className="sm:flex">
          {thumbnail && (
            <div className={`mb-4 flex-shrink-0 sm:mb-0 ${thumbMargin}`}>
              <img
                src={thumbnail}
                alt={info.title}
                loading="lazy"
                className="w-full sm:w-52"
                referrerPolicy="no-referrer"
              />
            </div>
          )}

          {isVideo ? (
            <VideoDownload info={info} className="w-full" />
          ) : (
            <FetchInfo
              info={info}
              onInfoUpdate={(newInfo) => setInfo(newInfo)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
