import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Select from 'components/Select';
import Button from 'components/Button';

export default function SubtitlesPanel({
  subtitles,
  buttonText = 'Download',
  ...props
}) {
  const { t } = useTranslation();

  const languages = Object.keys(subtitles);

  const [selectedLang, setSelectedLang] = React.useState(() => {
    // Set English as the default language if available
    return languages.includes('en') ? 'en' : languages[0];
  });

  // Selected language subtitles array
  const langSubtitles = selectedLang
    ? subtitles[selectedLang].slice().reverse()
    : [];

  const [subtitleIndex, setSubtitleIndex] = React.useState(0);
  const selectedSubtitle = langSubtitles[subtitleIndex] ?? {};

  return (
    <div {...props}>
      <Select
        id="video-subtitles"
        label={t('common:labels.select_lang')}
        value={selectedLang}
        onChange={(e) => setSelectedLang(e.target.value)}
      >
        {languages.map((lang) => (
          <option key={lang} value={lang}>
            {lang.toUpperCase()}
          </option>
        ))}
      </Select>

      {langSubtitles.length > 1 && (
        <Select
          id="video-subtitles"
          label={t('common:labels.select_file')}
          value={subtitleIndex}
          onChange={(e) => setSubtitleIndex(Number(e.target.value))}
          className="mt-4"
        >
          {langSubtitles.map(({ ext }, index) => (
            <option key={index} value={index}>
              {ext.toUpperCase()}
            </option>
          ))}
        </Select>
      )}

      <Button
        as="a"
        href={selectedSubtitle.url}
        color="secondary"
        target="_blank"
        rel="noopener noreferrer"
        referrerPolicy="no-referrer"
        className="w-full mt-4"
      >
        {buttonText} ({selectedSubtitle.ext?.toUpperCase()})
      </Button>
    </div>
  );
}
